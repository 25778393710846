import React from 'react'
import { graphql } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

import {
  Container,
  PostContainer,
  PageWrapper,
} from '../components/common/container'
import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'

import { HeroSection } from '../components/common/hero-section'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { featuredImage } = frontmatter
  return (
    <Layout>
      <SEO title={`Help: ${frontmatter.title}`} />
      <PageWrapper>
        <div>
          <BackgroundImage
            fluid={{
              base64: featuredImage.childImageSharp.lowRes.dataURI,
              src: featuredImage.childImageSharp.hiRes.dataURI,
            }}
          >
            <HeroSection>
              <h1>{frontmatter.title}</h1>
            </HeroSection>
          </BackgroundImage>
        </div>

        <PostContainer>
          <div className="blog-post-container">
            <div className="blog-post">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </PostContainer>
      </PageWrapper>
    </Layout>
  )
}
export const pageQuery = graphql`
  query(
    $path: String!
    $SQIP_HERO_SHAPE_COUNT: Int
    $SQIP_THUMB_SHAPE_COUNT: Int
  ) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          ...HeroImage
        }
      }
    }
  }
`
